// mini-sab
&.accommodation-category-overview,
&.accommodation-category-detail,
&.accommodation-detail {
	.mini-sab {
		display: none;
	}
}
.mini-sab {
	position: relative;
	.container {
		position: relative;
		@extend .d-flex;
		@extend .justify-content-center;

		.container-holder {
			flex: 1;
			max-width: 910px;
			z-index: 100;
			position: relative;
			margin: 15px 0 3vh 0;
			padding: 15px 1.5rem 15px 5rem;
			border-radius: 6px;
			background: $green;
			color: #fff;
			label,
			h2 {
				color: #fff !important;
			}

			@include media-breakpoint-down(md) {
				margin-top: -65px;
				padding-left: 40px;
				padding-right: 30px;
				.heading {
					font-size: $h3-font-size;
					left: auto;
					right: 0;
				}
			}

			// above eyecatcher
			@include media-breakpoint-up(lg) {
				margin-top: -100px;
				margin-bottom: auto;
				// position: absolute;
				// left: auto;
				// right: auto;
				// bottom: 0;
			}
			&::before {
				content: url("/images/arrow-left.svg");
				position: absolute;
				left: 0;
				top: 0;
				transform: translate(70%, -20%);
				@include media-breakpoint-down(md) {
					transform: translate(20%, -20%);
				}
			}
		}
	}
	.heading {
		position: absolute;
		top: -62px;
		left: 15px;
		font-family: $font-special;
		font-size: $h2-font-size;
		font-weight: 600;
	}
}
