// footer
.footer {
	.footer-intro {
		padding: 12vh 0;
		.column {
			text-align: center;
			.footer-text {
				margin: 1.5rem auto auto auto;

				h1, h2, h3, h4, h5, h6  {
					color: $gray;
				}

				&.names {
					h1, h2, h3, h4, h5, h6 {
						color: $black;
						font-family: $font-special;
					}
				}
			}
		}
		ul.footer-btns {
			display: flex;
			justify-content: center;
			gap: 1rem;
			li a {
				@extend .btn, .btn-primary;
				display: flex;
				gap: 0.65rem;
				align-items: center;
				i {
					transition: 0.35s ease;
				}
				&:hover {
					i {
						transform: scaleX(-1);
						transition: 0.35s ease;
					}
				}
			}
		}
	}
	.footer-contact {
		position: relative;
		background: $green;
		padding: 3vh 0;
		.title {
			position: absolute;
			top: 0;
			left: 0;
			width: max-content;
			transform: translate(0.5rem, -3.85vh);
			font-family: $font-special;
			font-size: $h3-font-size !important;
			color: $green;
			@include media-breakpoint-down(md) {
				left: 50%;
				transform: translate(-50%, -3.85vh);;
			}
		}
		.container {
			position: relative;
			.column {
				display: flex;
				flex-flow: row;
				justify-content: space-between;
				align-items: center;
				.footer-text {
					color: $white;
				}
				ul.socialmedia {
					display: flex;
					gap: 1rem;
					align-items: center;
					li {
						a {
							i {
								font-size: 30px;
								color: $white;
								transition: 0.35s ease;
							}
							.list-item-title {
								display: none;
							}
							&:hover {
								i {
									transform: scaleX(-1);
									transition: 0.35s ease;
								}
							}
						}
					}
				}
				@include media-breakpoint-down(md) {
					flex-flow: column;
					align-items: center;
					gap: 3vh;
					.footer-text {
						text-align: center;
					}
				}
			}
			&::before {
				content: url("/images/arrow-left.svg");
				position: absolute;
				left: 0;
				top: -3vh;
				transform: translate(-82%, -20%);
				@include media-breakpoint-down(md) {
					transform: translate(25%, -20%);
				}
			}
		}
	}
	.footer-copyright {
		background: $green-dark;
		.list {
			width: 100%;
			display: flex;
			gap: 1.25rem;
			justify-content: center;
			padding: 0.35rem 0;
			a {
				color: $white !important;
				text-transform: uppercase;
				font-size: 14px;
				text-decoration: none;
				&:hover {
					color: $orange !important;
				}
			}
		}
	}
}
