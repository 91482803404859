// header
.header {
	z-index: 998;
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	background: $white;
	transition: 0.5s;

	&.sticky {
		box-shadow: $shadow;
	}

	.container,
	.container-fluid {
		.container-holder {
			> .column {
				align-items: center;
				justify-content: space-between;
				gap: 2rem;
				@include media-breakpoint-down(lg) {
					flex-flow: row wrap;
					gap: 0;
				}

				@extend .navbar-expand-xl;
			}
		}
	}

	// menu-toggle
	.menu-toggle {
		i {
			color: $black;
			transition: 0.5s ease-out;
		}
		.navbar-toggler[aria-expanded="true"] {
			i {
				transform: rotate(360deg);
				transition: 0.5s ease-out;

				&::before {
					content: "\f00d" !important;
				}
			}
		}
	}

	// logo
	.logo {
		width: 152px;
		margin: 10px 10px 10px 0;

		a {
			@extend .d-block;

			img {
				@extend .w-100;
			}
		}
		@include media-breakpoint-down(md) {
			width: 129px;
		}
	}

	// menu
	.menu {
		@include media-breakpoint-down(lg) {
			order: 2;
			padding: 0 15px 6vh 15px;
			.dropdown-menu.show {
				position: static !important;
				border: none !important;
				transform: none !important;
				.nav-link {
					font-weight: 200 !important;
					margin-left: 0.5rem !important;
				}
			}
		}

		@include media-breakpoint-up(xl) {
			ul.navbar-nav {
				margin-left: auto;
				gap: 0.85rem;
				.nav-item {
					.nav-link {
						color: $green-dark;
						font-size: 16px;
					}
					&:hover,
					&.active {
						.nav-link {
							color: $orange;
						}
					}
				}
			}
			.dropdown-menu.show {
				border: none !important;
				box-shadow: none !important;
				padding: 1rem;
				border-radius: 0 0 8px 8px;
				.nav-item {
					.nav-link {
						&:hover {
							color: $green-dark !important;
						}
					}
				}
				&::after,
				&::before {
					display: none !important;
				}
			}
		}
	}
	// header button
	.header-btn {
		a {
			@extend .btn, .btn-primary;
		}
		@include media-breakpoint-down(md) {
			display: none;
			order: -1;
		}
	}

	// site-switcher
	.site-switcher {
	}
}
