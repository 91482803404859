// bg-*
// =========================================================================
section {
	&.bg-light,
	&.bg-dark {
		margin: 0;
		padding: 6vh 0;
	}
	&.bg-dark {
		background: $green !important;
		p {
			color: $white !important;
		}
	}
}

.lead-section + .content-section,
.lead-section + .bundle-overview-section {
	margin-top: calc(-6vh + 30px);
}

// omgeving section

.omgeving-section {
	.intro {
		padding: 6vh 0;
		.container-holder {
			margin: auto;
			max-width: 800px;
			.column {
				text-align: center;
			}
		}
	}
	.masonry {
		.card-columns {
			gap: 0.85rem !important;
			.card {
				margin: 0;
				border: none;
				border-radius: 0;
				.card-image {
					&::after {
						content: "";
						position: absolute;
						height: 100%;
						width: 100%;
						top: 0;
						left: 0;
						background: linear-gradient(
							(rgba(black, 0), rgba(black, 0.15) 90%)
						);
					}
					img {
						object-fit: cover;
					}
				}
				.card-body {
					position: absolute;
					top: 0;
					left: 0;
					z-index: 1;
					height: 100%;
					padding: 0;
					.card-caption {
						display: flex;
						flex-flow: column !important;
						height: 100%;
						align-items: flex-start !important;
						justify-content: flex-end;
						padding-left: 0.75rem;
					}

					.card-title {
						font-size: $h6-font-size;
						color: $white !important;
					}
					.card-subtitle {
						color: $white;
						font-size: 14px;
						text-transform: uppercase;
						font-family: $font-family-primary;
					}
					.card-description {
						display: none;
					}
				}

				&:hover {
					transform: translateY(-0.25rem);
					transition: 0.75s ease;
				}
				@include media-breakpoint-down(md) {
					margin-bottom: 1.2rem;
				}
			}
			> :nth-child(1n + 1) .card-image img {
				height: 475px;
				min-height: 475px;
				max-height: 475px;
			}
			> :nth-child(2n + 1) .card-image img {
				height: 233px;
				min-height: 233px;
				max-height: 233px;
			}
			> :nth-child(3n + 1) .card-image img {
				height: 400px;
				min-height: 400px;
				max-height: 400px;
			}
		}
	}
}

// accommodaties section
.accommodaties-section {
	.info {
		margin: 6vh auto;
		padding-top: 12vh;

		.container-holder {
			margin: auto;
			max-width: 740px;

			.column {
				text-align: center;
			}
		}
		@include media-breakpoint-down(md) {
			padding-top: 6vh !important;
		}
	}
	.grid-container {
		margin-top: -28vh;
		.grid-items {
			flex-wrap: nowrap !important;
			.item {
				max-width: 27%;

				.card {
					border: none !important;
					border-radius: 0;
					.card-image {
						&::after {
							content: "";
							position: absolute;
							height: 100%;
							width: 100%;
							top: 0;
							background: linear-gradient(
								(rgba(black, 0), rgba(black, 0.15) 90%)
							);
						}
					}
					.card-body {
						position: absolute;
						display: flex;
						height: 100%;
						align-items: flex-end;

						.card-caption {
							display: flex;
							height: 100%;
							align-items: flex-end;
						}
						.card-title {
							font-size: $h4-font-size;
							padding-left: 0.5rem;
							color: $white !important;
						}
						.card-subtitle,
						.card-description {
							display: none;
						}
					}
					&:hover {
						transform: translateY(-1rem);
						transition: 0.75s ease;
					}
				}
				&:nth-child(1) {
					transform: translateY(8%);
				}
				&:nth-child(2) {
					transform: translateY(18%);
				}
				&:nth-child(4) {
					transform: translateY(10%);
				}
			}
			@include media-breakpoint-down(md) {
				flex-flow: column wrap !important;
				background: $green;
				position: relative;
				&::before {
					content: "";
					position: absolute;
					width: 100%;
					height: 35vh;
					bottom: 0;
					background: $body-bg;
					z-index: 1;
				}
				.item {
					max-width: unset !important;
					transform: none !important;
					z-index: 2;
					.card {
						max-width: 80%;
						margin: auto;
					}
				}
			}
		}
	}
}

// faciliteiten-section
.faciliteiten-section {
	margin-top: 35vh !important;
	padding-bottom: 35vh !important;
	.eyecatcher-container {
		margin-top: -35vh;
	}
	.info {
		margin: 6vh auto;
		.container-holder {
			margin: auto;
			max-width: 740px;
			.column {
				text-align: center;
			}
		}
	}
	.faciliteiten {
		position: relative;
		margin-top: 12vh;
		.column {
			background: $green-light;
			border-radius: 6px;
			display: flex;
			flex-flow: row wrap;
			justify-content: flex-end;
			position: relative;
			padding: 1rem 3.5rem !important;
			.title {
				position: absolute;
				left: 10%;
				top: 0;
				width: max-content;
				transform: translateY(-100%);
				font-family: $font-special;
				@include media-breakpoint-down(md) {
					width: 80%;
					left: 0;
					font-size: 30px !important;
				}
			}
			div.faciliteiten-list {
				ul.faciliteiten-list {
					width: 100%;
					margin: 6vh auto;
					display: flex;
					flex-flow: row wrap;
					justify-content: space-between;
					gap: 1rem;
					li {
						width: 30%;
						display: flex;
						align-items: center;
						gap: 0.5rem;
						text-transform: uppercase;
						margin-bottom: 1rem;
						i {
							font-size: 24px;
							transition: 0.35s ease;
						}
						&:hover {
							i {
								transform: scaleX(-1);
								transition: 0.35s ease;
							}
						}
					}
					@include media-breakpoint-down(md) {
						flex-flow: column;
						gap: 0.5rem;
						margin: 6vh auto auto auto !important;
						li {
							width: 100%;
						}
					}
				}
			}
			@include media-breakpoint-down(md) {
				max-width: 90% !important;
				margin: auto !important;
				padding: 1rem !important;
				justify-content: flex-start;
			}
		}
		&::before {
			content: url("/images/arrow-left.svg");
			position: absolute;
			left: 50%;
			top: 0;
			transform: translate(-50%, -20%) scaleX(-1);
			z-index: 1;
			@include media-breakpoint-down(md) {
				left: auto;
				right: 10%;
			}
		}
	}
}

// lead-section
// =========================================================================
.lead-section {
	margin: 6vh 0;
	&.homepage {
		.container-one-column {
			.container-holder {
				max-width: 85%;
				margin: 0 auto;
				text-align: center;
				@include media-breakpoint-down(md) {
					max-width: initial !important;
				}
			}
		}
		@include media-breakpoint-down(md) {
			margin: 0 0;
		}
	}

	// bundles
	//  usps
	div.usps {
		ul.usps {
			display: flex;
			justify-content: center;
			gap: 2rem;
			li {
				display: flex;
				align-items: center;
				gap: 0.5rem;
				i {
					color: $green-light;
				}
			}
			@include media-breakpoint-down(md) {
				max-width: 85%;
				margin: auto;
				gap: 1rem;
				justify-content: flex-start;
				li {
					font-size: 18px;
					gap: 1rem;
					i {
						font-size: 30px;
					}
				}
			}
		}
		margin: 1.5vh 0 3vh 0;
	}

	.info-nav {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-between;
		margin-bottom: 30px;
	}

	.info {
		// accommodation
		.properties {
			@extend .d-flex;
			@extend .flex-wrap;
			@extend .list-unstyled;

			li {
				margin: 0 15px 0 0;
			}

			.icon {
				margin-right: 5px;
			}
		}

		// assortiment
		.former-price {
			font-size: $font-size-sm;
			text-decoration: line-through;
		}

		.discount-price {
			font-size: $font-size-sm;

			.amount {
				font-size: $font-size-lg;
			}

			margin-bottom: 1rem;
		}

		.price {
			margin-bottom: 1rem;

			.amount {
				font-size: $font-size-lg;
			}
		}
	}
}

// content-section
// =========================================================================
.content-section {
	margin: 6vh 0;
}

// banner-section
// =========================================================================
.banner-section {
}

// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	margin: 6vh 0;

	// collection
	.collection {
		.card {
			@extend .default-card !optional;
		}
	}
}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	margin: 6vh 0;
}

// gallery-section
// =========================================================================
.gallery-section {
	margin-top: calc(-6vh + 30px);
	margin-bottom: calc(-6vh + 30px);
	.gallery-horizontal,
	.gallery {
		justify-content: flex-start !important;
		.gallery-link,
		.gallery-link img {
			border-radius: 8px;
		}
	}
}

// booking-section
// =========================================================================
.booking-section {
	margin: 6vh 0;

	h2 {
		margin-bottom: 30px;
	}
}

// contact-section
// =========================================================================
.contact-section {
	margin: 6vh 0;
}
